<template>
    <div>
        <v-app-bar app dense color='primary' dark :clipped-left='true'>
            <v-app-bar-nav-icon @click='toggleDrawer' v-if='isAuthenticated && !$route.path.includes("team-select")' />
            <v-toolbar-title style='cursor: default'> Fincom AML Platform </v-toolbar-title>
            <div class='flex-grow-1'></div>
            <div class='mx-3' style='cursor: default'>
                <v-icon class='mx-2'>{{ roleIcon }}</v-icon>
                <span class='text-lowercase'>
                    {{ userRole }}
                </span>
            </div>
            <v-chip variant='outlined' v-if='userName' prepend-icon='mdi-account'>
                {{ userName }}
            </v-chip>
            <v-icon v-if='userRole' @click='doLogout' size='30' class='mx-3'>mdi-logout</v-icon>
        </v-app-bar>
        <MainDrawer 
            v-if='isAuthenticated && userRole && !$route.path.includes("team-select")'
            :isDrawerOpen='isDrawerOpen' 
            @updateToggle='updateToggle'
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import MainDrawer from '@/components/menu/MainDrawer';
import ActionRoles from '@/helpers/actionRoles';

export default {
    components: {
        MainDrawer,
    },
    data() {
        return {
            isDrawerOpen: false,
        };
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'userName', 'userRole']),
        roleIcon(){
          return ActionRoles.adminRoles.includes(this.userRole) ? 'mdi-shield-account' : 'mdi-account-circle';
        },
    },
    watch: {
        isAuthenticated(newValue) {
            if (!newValue) {
                this.$router.go({ name: 'login' });
            }
        },
    },
    methods: {
        ...mapActions({ logout: AUTH_LOGOUT }),
        toggleDrawer() {
            this.isDrawerOpen = !this.isDrawerOpen;
        }, updateToggle(value) {
            this.isDrawerOpen = value;
        },
        async doLogout() {
            await this.logout({token: '', isForce: false, messageForLogout: 'Manual logout'});
            this.$router.go({ name: 'login' });
        },
    },
};
</script>
